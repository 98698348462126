import axios from 'axios';

const BASE_URL = 'https://react-ecom.acesoftech.co.in/api/products';

export const addProductAPI = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/add`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set the Content-Type header
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to add product');
    }
    return response.data;
  } catch (error) {
    console.error('Error adding product:', error.message);
    throw error;
  }
};


export const updateProductAPI = async (productId, formData) => {
  try {
    const response = await axios.put(`${BASE_URL}/update/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set the Content-Type header
      },
    });
    
    if (response.status !== 200) {
      throw new Error('Failed to update product');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating product:', error.message);
    throw error;
  }
};

export const deleteProductAPI = async (productId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/delete/${productId}`);
    
    if (response.status !== 200) {
      throw new Error('Failed to delete product');
    }

    return response.data;
  } catch (error) {
    console.error('Error deleting product:', error.message);
    throw error;
  }
};

export const fetchAllProductsAPI = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/all`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all products:', error.message);
    throw error;
  }
};

export const fetchProductByIdAPI = async (productId) => {
  try {
    const response = await axios.get(`${BASE_URL}/${productId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product by ID:', error.message);
    throw error;
  }
};
