import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts, deleteProduct } from '../../slices/productSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { selectImagePath } from  '../../slices/globalpaths';

function ProductView() {
  const dispatch = useDispatch();

  const imagePath = useSelector(selectImagePath);

  const { loading, error, products } = useSelector((state) => state.products);


  
  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  const handleEdit = (productId) => {
    console.log("Edit product with ID:", productId);
  };

  const handleDelete = (productId) => {
    dispatch(deleteProduct(productId));
  };

  return (
    <div className="container">
      <h2>Product List</h2>
      {loading && <div>Loading...</div>}
      {error && <div className="text-danger">{error}</div>}
      {products && products.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Images</th> {/* Changed heading to "Images" */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td>{product.category}</td>
                <td>{product.productName}</td>
                <td>{product.description}</td>
                <td>{product.price}</td>
                <td>
                  {/* Render multiple image elements */}
                  {product.product_images.map((image, index) => (
                    <img key={index} src={imagePath + image} alt={`Product ${index + 1}`} style={{ width: '100px', height: 'auto', marginRight: '5px' }} />
                  ))}
                </td>
                <td>
                  <Link to={`/edit-product/${product._id}`} className="btn btn-primary btn-sm">
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </Link>
                  <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(product._id)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No products found</div>
      )}
    </div>
  );
}

export default ProductView;
