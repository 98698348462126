import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBlogArticle } from '../../slices/blogSlice';
import { fetchCategories } from '../../slices/blogCategorySlice'; // Import fetchCategories action

const AddBlogArticle = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [content, setContent] = useState('');
  const [featured, setFeatured] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  
  // Fetch category list from Redux store
  const categories = useSelector(state => state.blogCategories.categories);
  const status = useSelector(state => state.blogCategories.status);

  useEffect(() => {
    // Dispatch action to fetch categories when component mounts
    dispatch(fetchCategories()); // Dispatch the fetchCategories action
  }, [dispatch]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image file
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare form data with image file
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('content', content);
    formData.append('featured', featured);
    formData.append('category', selectedCategory); // Append selected category to form data

    console.log('AAA',formData);

    dispatch(addBlogArticle(formData)); // Dispatch action with form data
    setTitle('');
    setImage(null);
    setContent('');
    setFeatured(false);
    setSelectedCategory(''); // Reset selected category
  };

  return (
    <div>
      <h2>Add New Blog Article</h2>
      <form onSubmit={handleSubmit}>

      <div className="mb-3">
          <label htmlFor="category" className="form-label">Category</label>
          <select className="form-select" id="category" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input type="file" className="form-control" id="image" onChange={handleImageChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">Content</label>
          <textarea className="form-control" id="content" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="featured" checked={featured} onChange={(e) => setFeatured(e.target.checked)} />
          <label className="form-check-label" htmlFor="featured">Featured</label>
        </div>
     
        <button type="submit" className="btn btn-primary">Add Article</button>
      </form>
    </div>
  );
};

export default AddBlogArticle;
