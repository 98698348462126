import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ sidebarOpen, toggleSidebar, toggleSubMenu, openSubMenu }) => {
  return (
    <nav id="sidebarMenu" className={`col-md-3 col-lg-2 d-md-block bg-light sidebar collapse ${sidebarOpen ? 'show' : ''}`}>
      <div className="position-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active main-cat" aria-current="page" href="#" onClick={() => toggleSubMenu('categories')}>Categories</a>
            <ul className={`nav flex-column sub-menu ${openSubMenu === 'categories' ? 'show' : ''}`}>
              <li className="nav-item">
                <Link to="/add-category" className="nav-link">- Add Category</Link>
              </li>
              <li className="nav-item">
                <Link to="/view-category" className="nav-link">- View Category</Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link main-cat" href="#" onClick={() => toggleSubMenu('products')}>Products</a>
            <ul className={`nav flex-column sub-menu ${openSubMenu === 'products' ? 'show' : ''}`}>
              <li className="nav-item">
                <Link to="/add-product" className="nav-link">- Add Products</Link>
              </li>
              <li className="nav-item">
                <Link to="/view-products" className="nav-link">- View Products</Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={() => toggleSubMenu('orders')}>Orders</a>
            <ul className={`nav flex-column sub-menu ${openSubMenu === 'orders' ? 'show' : ''}`}>
              <li className="nav-item">
                <a className="nav-link" href="#">- View Orders</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">- Archived Orders</a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={() => toggleSubMenu('blogs')}>Blogs</a>
            <ul className={`nav flex-column sub-menu ${openSubMenu === 'blogs' ? 'show' : ''}`}>
              <li className="nav-item">
                <Link to="/add-blog-category" className="nav-link">- Add Blog Category</Link>
              </li>
              <li className="nav-item">
                <Link to="/view-blog-category" className="nav-link">- View Blog Category</Link>
              </li>
              <li className="nav-item">
                <Link to="/add-blog-article" className="nav-link">- Add Blogs</Link>
              </li>
              <li className="nav-item">
                <Link to="/view-blog-article" className="nav-link">- View Blogs</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;
