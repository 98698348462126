import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, selectError, selectLoading, selectUser } from '../../slices/userSlice';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const userFromSession = JSON.parse(sessionStorage.getItem('user'));
  const tokenFromSession = sessionStorage.getItem('token');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

 useEffect(() => {
  if (user.user!='' && userFromSession!=null && tokenFromSession!=null) {
      navigate('/dashboard');
   }
   setIsInitialRender(false);
}, [user, navigate, isInitialRender]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(loginUser(formData));
  };

  //console.log('KKK',user.user)
  return (
    <div className="container">
      <h2 className="mb-4">Login</h2>
      {error && <p className="text-danger">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
