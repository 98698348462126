// // blogSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// // Define initial state
// const initialState = {
//   blogs: [],
//   loading: false,
//   error: null
// };
// const API='http://localhost:5000/blogs'
// // Define thunk for fetching blogs
// export const fetchBlogs = createAsyncThunk(
//   'blogs/fetchBlogs',
//   async () => {
//     const response = await axios.get('/api/blogs');

//     console.log('KKKK',response);

//     return response.data;
//   }
// );

// // Create a slice
// const blogSlice = createSlice({
//   name: 'blogs',
//   initialState,
//   reducers: {},
//   extraReducers: {
//     [fetchBlogs.pending]: (state) => {
//       state.loading = true;
//       state.error = null;
//       console.log('AAPENDINGASSSS', state.error);
//     },
//     [fetchBlogs.fulfilled]: (state, action) => {
//       state.loading = false;
//       state.blogs = action.payload;

//       console.log('FULFILLED',state.blogs);
//     },
//     [fetchBlogs.rejected]: (state, action) => {
//       state.loading = false;
//       state.error = action.error.message;

//       console.log('REJECT', state.error);
//     }
//   }
// });

// // Export actions and reducer
// export const blogActions = blogSlice.actions;
// export default blogSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API = 'https://react-ecom.acesoftech.co.in/api/blogs';

// Async thunk to fetch all blog articles
export const fetchBlogArticles = createAsyncThunk(
  'blogArticles/fetchBlogArticles',
  async () => {
    const response = await axios.get(API);
    
    //console.log('BLOGS',response.data);

    return response.data;
  }
);

// Async thunk to fetch a single blog article by ID
export const fetchSingleBlogArticle = createAsyncThunk(
  'blogArticles/fetchSingleBlogArticle',
  async (articleId) => {
    const response = await axios.get(`${API}/${articleId}`);
    return response.data;
  }
);

// Async thunk to add a new blog article
// export const addBlogArticle = createAsyncThunk(
//   'blogArticles/addBlogArticle',
//   async (articleData, { rejectWithValue }) => {
//     try {
//       console.log('articleData',articleData)
//       const response = await axios.post(API+'/add-blog', articleData);
//       console.log('DATA',response.data);
//       return response.data;
//     } catch (error) {
//       // If an error occurs, reject the promise with the error message
//       return rejectWithValue('ERROR',error.message);
//     }
//   }
// );
export const addBlogArticle = createAsyncThunk(
  'blogArticles/addBlogArticle',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(API+'/add-blog', formData, {
        //const response = await axios.post('http://localhost/react-php/data.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Set the Content-Type header
        },
      });
      console.log('response',response)
      if (response.status !== 200) {
        throw new Error('Failed to add product');
      }
      return response.data;
    } catch (error) {
      console.error('Error adding product:', error.message);
      return rejectWithValue(error.message);
    }
  }
);


// Async thunk to update a blog article
export const updateBlogArticle = createAsyncThunk(
  'blogArticles/updateBlogArticle',
  async ({ articleId, articleData }) => {
    const response = await axios.put(`${API}/${articleId}`, articleData);
    return response.data;
  }
);

// Async thunk to delete a blog article
// Async thunk to delete a blog article
export const deleteBlogArticle = createAsyncThunk(
  'blogArticles/deleteBlogArticle',
  async (articleId, { dispatch }) => {
    await axios.delete(`${API}/${articleId}`);
    dispatch(fetchBlogArticles()); // Dispatch fetchBlogArticles after successful deletion
    return articleId;
  }
);


const blogArticleSlice = createSlice({
  name: 'blogArticles',
  initialState: {
    articles: [],
    singleArticle: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBlogArticles.pending, state => {
        state.loading = true;
      })
      .addCase(fetchBlogArticles.fulfilled, (state, action) => {
        state.loading = false;
        state.articles = action.payload;
        console.log('fulfilled',state.articles)
        state.error = null;
      })
      .addCase(fetchBlogArticles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleBlogArticle.pending, state => {
        state.loading = true;
      })
      .addCase(fetchSingleBlogArticle.fulfilled, (state, action) => {
        state.loading = false;
        state.singleArticle = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleBlogArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addBlogArticle.pending, state => {
        state.loading = true;
      })
      .addCase(addBlogArticle.fulfilled, (state, action) => {
        state.loading = false;
        state.articles.push(action.payload);
        state.error = null;
      })
      .addCase(addBlogArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateBlogArticle.pending, state => {
        state.loading = true;
      })
      .addCase(updateBlogArticle.fulfilled, (state, action) => {
        state.loading = false;
        const updatedArticle = action.payload;
        state.articles = state.articles.map(article =>
          article.id === updatedArticle.id ? updatedArticle : article
        );
        state.error = null;
      })
      .addCase(updateBlogArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteBlogArticle.pending, state => {
        state.loading = true;
      })
      .addCase(deleteBlogArticle.fulfilled, (state, action) => {
        state.loading = false;
        const deletedArticleId = action.payload;
        state.articles = state.articles.filter(article => article.id !== deletedArticleId);
        state.error = null;
      })
      .addCase(deleteBlogArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default blogArticleSlice.reducer;
