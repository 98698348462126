import { useSelector } from 'react-redux';
import { selectUser, selectLoading, selectError } from './slices/userSlice';
import React, { useEffect, useState } from 'react'; // Import useState
import { useNavigate } from 'react-router-dom';

// Higher-order component for authenticating routes
const ProtectedRoute = ({ element, ...rest }) => {
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [storedUser, setStoredUser] = useState(null); // Define storedUser using useState
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    const userFromSession = JSON.parse(sessionStorage.getItem('user'));
    const tokenFromSession = sessionStorage.getItem('token');

    // console.log('user',user.user);
    // console.log('userFromSession',userFromSession);
    // console.log('tokenFromSession',tokenFromSession);

    if (user.user=='' && userFromSession==null && tokenFromSession==null) {
     // console.log('Send Login');
      navigate('/login'); // Redirect to login page if not authenticated
    } else {
      if (user) {
        setStoredUser(user); // Set storedUser state
      } else {
        setStoredUser(userFromSession); // Set storedUser state from session storage
      }
    }
  }, [user, loading, error, navigate]);

  // If authenticated, render the specified element
  return React.cloneElement(element, rest);
};

export default ProtectedRoute;
