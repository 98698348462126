import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Sidebar from './components/common/Sidebar';
import Dashboard from './components/auth/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import RegistrationForm from './components/auth/RegistrationForm';
import LoginForm from './components/auth/LoginForm';
import AddCategoryForm from './components/categories/AddCategories';
import CategoryView from './components/categories/ViewCategories';
// import EditCategoryForm from './components/products/EditProduct';
import EditCategoryForm from './components/categories/EditCategories';
import AddProductForm from './components/products/AddProducts';
import ProductView  from './components/products/ViewProducts';
import EditProductForm from './components/products/EditProduct';
import './App.css';
import AddBlogArticle from './components/blogs/AddBlogArticles';
import AddBlogCategory from './components/blogs/AddBlogCategory';
import EditBlogCategory from './components/blogs/EditBlogCategory';
import EditBlogArticle from './components/blogs/EditBlogArticles';
import ViewBlogCategory from './components/blogs/ViewBlogCategory';
import ViewBlogArticles from './components/blogs/ViewBlogArticles';

function App() {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSubMenu = (menu) => {
    setOpenSubMenu(menu === openSubMenu ? null : menu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <div className="container-fluid main-header">
        {/* Header and Sidebar wrapped inside a single parent div */}
        <div>
          {/* Header */}
          <Header toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />

          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            toggleSubMenu={toggleSubMenu}
            openSubMenu={openSubMenu}
          />
        </div>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 main-area">
          <Routes>
            {/* Main Content */}
            <Route exact path="/" element={<Dashboard />} />
            {/* Categories */}
    
            <Route path="/add-category" element={<ProtectedRoute element={<AddCategoryForm />} />} />

            <Route path="/view-category" element={<ProtectedRoute element={<CategoryView />} />} />

            <Route path="/edit-category/:categoryId" element={<EditCategoryForm />} />
            {/* Products */}
            
            <Route path="/add-product" element={<ProtectedRoute element={<AddProductForm />} />} />

            <Route path="/view-products" element={<ProtectedRoute element={<ProductView />} />} />
            
            <Route path="/edit-product/:productId" element={<EditProductForm />} />
            
            <Route path="/register" element={<RegistrationForm />} />

            <Route path="/login" element={<LoginForm />} />

            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            
            <Route path="/add-blog-category" element={<ProtectedRoute element={<AddBlogCategory />} />} />
                     
            <Route path="/view-blog-category" element={<ProtectedRoute element={<ViewBlogCategory />} />} />
            
            <Route path="/edit-blog-category/:blogCategoryId" element={<EditBlogCategory />} />
            
            <Route path="/add-blog-article" element={<ProtectedRoute element={<AddBlogArticle />} />} />
            <Route path="/view-blog-article" element={<ProtectedRoute element={<ViewBlogArticles />} />} />
           
            <Route path="/edit-blog-article/:blogArticleId" element={<ProtectedRoute element={<EditBlogArticle />} />} />
           

            {/* <Route path="/dashboard"  element={<Dashboard />} /> */}
           
            {/* Add more routes for other pages if needed */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
