import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBlogArticles, deleteBlogArticle } from '../../slices/blogSlice';

const ViewBlogArticle = () => {
  const dispatch = useDispatch();
  const articles = useSelector(state => state.blogArticles.articles);
  const status = useSelector(state => state.blogArticles.status);
  const error = useSelector(state => state.blogArticles.error);

  console.log('Articles', articles);

  useEffect(() => {
    //if (status === 'idle') {
      dispatch(fetchBlogArticles());
   // }
  }, [status, dispatch]);

  const handleDelete = async (articleId) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      await dispatch(deleteBlogArticle(articleId));
      dispatch(fetchBlogArticles()); // Fetch articles again after deletion
    }
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Blog Articles</h2>
      {articles.length === 0 ? (
        <p>No articles available.</p>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Content</th>
              <th>Featured</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {articles.map(article => (
              <tr key={article._id}>
                <td>{article.title}</td>
                <td><img src={article.image} alt={article.title} style={{ maxWidth: '100px' }} /></td>
                <td>{article.content}</td>
                <td>{article.featured ? 'Yes' : 'No'}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDelete(article._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ViewBlogArticle;
