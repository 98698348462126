import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// Define the initial state
const initialState = {
  categories: [],
  status: 'idle',
  error: null,
};

const API="https://react-ecom.acesoftech.co.in";
// Define thunk for fetching all categories
export const fetchCategories = createAsyncThunk('blogCategories/fetchCategories', async () => {
  const response = await axios.get(API+'/api/blogCategories/blogCategories');
  return response.data;
});

// Define thunk for adding a new category
export const addCategory = createAsyncThunk('blogCategories/addCategory', async (categoryData) => {
  const response = await axios.post(API+'/api/blogCategories/blogCategories', categoryData);
  return response.data;
});

// Define thunk for updating a category
export const updateCategory = createAsyncThunk('blogCategories/updateCategory', async (categoryData) => {
  const response = await axios.put(API+`/api/blogCategories/blogCategories/${categoryData.id}`, categoryData);
  return response.data;
});

// Define thunk for fetching a single category
export const fetchSingleCategory = createAsyncThunk('blogCategories/fetchSingleCategory', async (categoryId) => {
  const response = await axios.get(API+`/api/blogCategories/blogCategories/${categoryId}`);
  return response.data;
});

// Define thunk for deleting a category
export const deleteCategory = createAsyncThunk('blogCategories//deleteCategory', async (categoryId) => {
  await axios.delete(API+`/api/blogCategories/blogCategories/${categoryId}`);
  return { id: categoryId }; // Return deleted category ID
});

// Create the slice
const BlogCategorySlice = createSlice({
  name: 'blogCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const existingCategory = state.categories.find(category => category.id === updatedCategory.id);
        if (existingCategory) {
          Object.assign(existingCategory, updatedCategory);
        }
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        const deletedCategoryId = action.payload.id;
        state.categories = state.categories.filter(category => category.id !== deletedCategoryId);
      });
  },
});

export default BlogCategorySlice.reducer;