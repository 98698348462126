import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addCategoryAPI, updateCategoryAPI, deleteCategoryAPI, fetchAllCategoriesAPI, fetchCategoryByIdAPI } from '../actions/categoryApi';

// Async thunk to fetch all categories
export const fetchAllCategories = createAsyncThunk(
  'categories/fetchAllCategories',
  async () => {
    try {
      const response = await fetchAllCategoriesAPI();
      return response;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to fetch a category by ID
export const fetchCategoryById = createAsyncThunk(
  'categories/fetchCategoryById',
  async (categoryId) => {
    try {
      const response = await fetchCategoryByIdAPI(categoryId);
      return response;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle adding a category
export const addCategory = createAsyncThunk(
  'categories/addCategory',
  async (formData) => {
    try {
      const response = await addCategoryAPI(formData);
      return response.data;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle updating a category
export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async ({ categoryId, formData }) => {
    try {
      const response = await updateCategoryAPI(categoryId, formData);
      return response.data;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle deleting a category
export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (categoryId) => {
    try {
      await deleteCategoryAPI(categoryId);
      return categoryId;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Create slice for category
const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    error: null,
    categories: [],
    singleCat: {
      loading: false,
      error: null,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categories = action.payload;
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategoryById.pending, (state) => {
        state.singleCat.loading = true;
        state.singleCat.error = null;
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        state.singleCat.loading = false;
        state.singleCat.error = null;
        console.log('Fulfilled',action.payload)
        state.singleCat.data = action.payload;
        console.log('Fulfilled2',state.singleCat.data)
      })
      .addCase(fetchCategoryById.rejected, (state, action) => {
        state.singleCat.loading = false;
        state.singleCat.error = action.payload;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        console.log('Before Filter',state.categories);
        // Filter out the deleted category from the categories array
        state.categories = state.categories.filter(category => category._id !== action.payload);
      
        console.log('After Filter',state.categories);
       // console.log();

      })
      
      .addMatcher(
        (action) => [addCategory.pending, updateCategory.pending, deleteCategory.pending].includes(action.type),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => [addCategory.fulfilled, updateCategory.fulfilled, deleteCategory.fulfilled].includes(action.type),
        (state) => {
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => [addCategory.rejected, updateCategory.rejected, deleteCategory.rejected].includes(action.type),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default categorySlice.reducer;
