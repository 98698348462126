import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCategories,deleteCategory } from '../../slices/categorySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import {  Link } from 'react-router-dom';
function CategoryView() {
  const dispatch = useDispatch();
  const { loading, error, categories } = useSelector((state) => state.categories);

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  const handleEdit = (categoryId) => {
    // Implement edit functionality
    console.log("Edit category with ID:", categoryId);
  };

  const handleDelete = (categoryId) => {
    // Dispatch the deleteCategory action with the categoryId
    dispatch(deleteCategory(categoryId));
  };

  return (
    <div className="container">
      <h2>Category List</h2>
      {loading && <div>Loading...</div>}
      {error && <div className="text-danger">{error}</div>}
      {categories && categories.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>{category.categoryName}</td>
                <td>{category.description}</td>
                <td>{category.status}</td>
                <td>
                <Link to={`/edit-category/${category._id}`} className="btn btn-primary btn-sm">
  <FontAwesomeIcon icon={faEdit} /> Edit
</Link>

                  <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(category._id)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No categories found</div>
      )}
    </div>
  );
}

export default CategoryView;
