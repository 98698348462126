import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProductById, updateProduct } from '../../slices/productSlice';
import { fetchAllCategories } from '../../slices/categorySlice';
import { selectImagePath } from '../../slices/globalpaths';

function EditProductForm() {
  const dispatch = useDispatch();
  const imagePath = useSelector(selectImagePath);
  const { loading, error, products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.categories);
  const { productId } = useParams();

  const [formData, setFormData] = useState({
    category: '',
    productName: '',
    description: '',
    price: '',
    product_images: [], // State to store multiple images
  });

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    dispatch(fetchAllCategories());
    if (productId) {
      dispatch(fetchProductById(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (productId) {
      const selectedProduct = products.find((product) => product._id === productId);
      if (selectedProduct) {
        setFormData({
          category: selectedProduct.category,
          productName: selectedProduct.productName,
          description: selectedProduct.description,
          price: selectedProduct.price,
          product_images: selectedProduct.product_images || [], // Set images array from the fetched product
        });
      }
    }
  }, [productId, products]);

  //console.log('productsss',products);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, product_images: [...formData.product_images, ...files] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('before Update',formData)
  
    try {
      const productData = new FormData();
      productData.append('category', formData.category);
      productData.append('productName', formData.productName);
      productData.append('description', formData.description);
      productData.append('price', formData.price);
      
      formData.product_images.forEach((file, index) => {
        productData.append('product_images', file);
      });
  
      await dispatch(updateProduct({ productId, formData: productData }));
      setSuccessMessage('Product updated successfully!');
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <div className="container">
      <h2>Edit Product</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category
          </label>
          <select
            className="form-select"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option
                key={category._id}
                value={category._id}
                selected={formData.category === category._id}
              >
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="productName" className="form-label">
            Product Name
          </label>
          <input
            type="text"
            className="form-control"
            id="productName"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Price
          </label>
          <input
            type="number"
            className="form-control"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="images" className="form-label">
            Images
          </label>
          <input
            type="file"
            className="form-control"
            id="product_images"
            name="product_images"
            onChange={handleImageChange}
            multiple // Allow multiple file selection
          />

<br/>
<ul>
  {products.map(product => {
    if (product._id === productId) {
      return product.product_images.map((image, index) => (
        <li key={index} style={{ float: 'left', marginLeft: '15px' }}>
          <img
            src={imagePath + image}
            alt={`Product Image ${index + 1}`}
            style={{ width: '100px', height: 'auto' }}
          />
        </li>
      ));
    }
    return null;
  })}
</ul>



        </div>
      
       <br/> <br/><br/><button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <div className="text-danger">{error}
        <br/> <br/><br/>
        </div>}
      </form>
    </div>
  );
}

export default EditProductForm;
