// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state
const initialState = {
  user: '',
  error: '',
  loading: false,
  token: '', // Add token state to store JWT token
};

//const apiLink = 'http://localhost:5000';
const apiLink = 'https://react-ecom.acesoftech.co.in';

// Async thunk to handle user registration
export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiLink}/api/auth/register`, userData);
     // console.log('AAA',response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to handle user login
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiLink}/api/auth/login`, userData);
      // After successful login
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      sessionStorage.setItem('token', response.data.token);
      // Return the response data
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to handle user logout
// Async thunk to handle user logout
export const logoutUser = createAsyncThunk(
    'user/logoutUser',
    async (_, { rejectWithValue }) => {
      try {
        await axios.post(`${apiLink}/api/auth/logout`);
        
        // Clear user data and token from sessionStorage upon logout
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  

// Slice to manage user state
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Action to clear user state on logout
    clearUserState: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token; // Store JWT token upon successful login
        state.error = null;

        // console.log('Fulfilled',state.user);
        // console.log('Token Fulfilled',state.user);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.errorMessage;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token; // Store JWT token upon successful registration
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.errorMessage;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.errorMessage;
      });
  },
});


// Selectors
// export const selectUser = (state) => state.user?.user; // Use optional chaining to handle null state
// export const selectToken = (state) => state.user?.token; // Use optional chaining to handle null state
// export const selectError = (state) => state.user?.error; // Use optional chaining to handle null state
// export const selectLoading = (state) => state.user?.loading; // Use optional chaining to handle null state

export const selectUser = (state) => state.user; // Use optional chaining to handle null state
export const selectToken = (state) => state.user?.token; // Use optional chaining to handle null state
export const selectError = (state) => state.user?.error; // Use optional chaining to handle null state
export const selectLoading = (state) => state.user?.loading; // Use optional chaining to handle null state
///console.log('STATE',selectUser);

// Export clearUserState action creator
export const { clearUserState } = userSlice.actions;

// Reducer
export default userSlice.reducer;
