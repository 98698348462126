import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addProductAPI, updateProductAPI, deleteProductAPI,
   fetchAllProductsAPI, fetchProductByIdAPI } from '../actions/productApi';

// Async thunk to fetch all products
export const fetchAllProducts = createAsyncThunk(
  'products/fetchAllProducts',
  async () => {
    try {
      const response = await fetchAllProductsAPI();
      return response;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to fetch a product by ID
export const fetchProductById = createAsyncThunk(
  'products/fetchProductById',
  async (productId) => {
    try {
      const response = await fetchProductByIdAPI(productId);
      return response;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle adding a product
export const addProduct = createAsyncThunk(
  'products/addProduct',
  async (formData) => {
    try {
      const response = await addProductAPI(formData);
      return response.data;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle updating a product
export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async ({ productId, formData }) => {
    try {
      const response = await updateProductAPI(productId, formData);
      return response.data;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Async thunk to handle deleting a product
export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (productId) => {
    try {
      await deleteProductAPI(productId);
      return productId;
    } catch (error) {
      return Promise.reject(error.message);
    }
  }
);

// Create slice for product
const productSlice = createSlice({
  name: 'products',
  initialState: {
    loading: false,
    error: null,
    products: [],
    singleProduct: {
      loading: false,
      error: null,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = action.payload;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.singleProduct.loading = true;
        state.singleProduct.error = null;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.singleProduct.loading = false;
        state.singleProduct.error = null;
        state.singleProduct.data = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.singleProduct.loading = false;
        state.singleProduct.error = action.payload;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = state.products.filter(product => product._id !== action.payload);
      })
      .addMatcher(
        (action) => [addProduct.pending, updateProduct.pending, deleteProduct.pending].includes(action.type),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => [addProduct.fulfilled, updateProduct.fulfilled, deleteProduct.fulfilled].includes(action.type),
        (state) => {
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => [addProduct.rejected, updateProduct.rejected, deleteProduct.rejected].includes(action.type),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default productSlice.reducer;
