import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSingleCategory, updateCategory } from '../../slices/blogCategorySlice';

const EditBlogCategory = ({ match }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryId } = match.params;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState('');
  const [active, setActive] = useState(true);

  const category = useSelector(state => state.blogCategories.category);
  const status = useSelector(state => state.blogCategories.status);
  const error = useSelector(state => state.blogCategories.error);

  useEffect(() => {
    dispatch(fetchSingleCategory(categoryId));
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (status === 'succeeded' && category) {
      setName(category.name);
      setDescription(category.description);
      setParentId(category.parentId);
      setActive(category.active);
    }
  }, [status, category]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedCategory = {
      id: categoryId,
      name,
      description,
      parentId,
      active
    };

    dispatch(updateCategory(updatedCategory));
    navigate('/view-blog-categories'); // Redirect to view blog categories page
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Edit Blog Category</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="parentId" className="form-label">Parent ID</label>
          <input type="text" className="form-control" id="parentId" value={parentId} onChange={(e) => setParentId(e.target.value)} />
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="active" checked={active} onChange={(e) => setActive(e.target.checked)} />
          <label className="form-check-label" htmlFor="active">Active</label>
        </div>
        <button type="submit" className="btn btn-primary">Update Category</button>
      </form>
    </div>
  );
};

export default EditBlogCategory;
