import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, fetchAllCategories } from '../../slices/categorySlice';

function AddCategoryForm() {
  const dispatch = useDispatch();
  const { loading, error, categories } = useSelector((state) => state.categories); // Assuming categories are stored in the state

  const [formData, setFormData] = useState({
    parentCategory: '',
    categoryName: '',
    description: '',
    status: '',
  });
  

  const [successMessage, setSuccessMessage] = useState('');

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addCategory(formData));
      setSuccessMessage('Category added successfully!');
      // Optionally, you can reset the form after success
      setFormData({
        parentCategory: '',
        categoryName: '',
        description: '',
        status: '',
      });
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  return (
    <div className="container">
      <h2>Add Category</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="parentCategory" className="form-label">
            Parent Category
          </label>
          <select
            className="form-select"
            id="parentCategory"
            name="parentCategory"
            value={formData.parentCategory}
            onChange={handleChange}
          >
            <option value="">Select Parent Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>{category.categoryName}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="categoryName" className="form-label">
            Category Name
          </label>
          <input
            type="text"
            className="form-control"
            id="categoryName"
            name="categoryName"
            value={formData.categoryName}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <select
            className="form-select"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <div className="text-danger">{error}</div>}
      </form>
    </div>
  );
}

export default AddCategoryForm;
