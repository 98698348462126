import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addCategory, fetchAllCategories,fetchCategoryById, updateCategory } from '../../slices/categorySlice';

function EditCategoryForm() {
  const dispatch = useDispatch();

  const { loading, error, categories } = useSelector((state) => state.categories);
  const { loading: singleLoading, error: singleError, data: singleCat } = useSelector((state) => state.categories.singleCat);
  
   

console.log('SINGLEEEEEEE',singleCat)


  const { categoryId } = useParams();

  const [formData, setFormData] = useState({
    parentCategory: '',
    categoryName: '',
    description: '',
    status: '',
  });


  const [singleCats, setSingleCats] = useState({
    parentCategory: '',
    categoryName: '',
    description: '',
    status: '',
  });


  const [successMessage, setSuccessMessage] = useState('');

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(fetchAllCategories());
    // if (categoryId) {
    //   dispatch(fetchCategoryById(categoryId));
    // }
  }, [dispatch, categoryId]);


  useEffect(() => {
    // Set the selected parentCategory based on the categoryId
    const selectedCategory = categories.find(category => category._id === categoryId);
    if (selectedCategory) {
      setFormData(prevFormData => ({
        ...prevFormData,
        parentCategory: selectedCategory._id,
      }));
    }
  }, [categories, categoryId]);



  // useEffect(() => {
  //   // Check if categoryId exists before dispatching the action
  //   if (categoryId) {
  //     dispatch(fetchCategoryById(categoryId))
  //       .then((response) => {

  //         console.log('RSSSSSS',response.data);
  //         // Update singleCats state with the fetched category data
  //         setSingleCats({
  //           categoryName: response.data.categoryName || '',
  //           description: response.data.description || '',
  //           status: response.data.status || '',
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching category:', error);
  //         // Optionally handle error
  //       });
  //   }
  // }, [dispatch, categoryId]);





  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategoryById(categoryId));
        console.log('RSSSSSS', response.payload); // Check the structure of the response
        // Update singleCats state with the fetched category data
        setSingleCats({
          parentCategory: response.payload.parentCategory || '', // Set parentCategory here
          categoryName: response.payload.categoryName || '',
          description: response.payload.description || '',
          status: response.payload.status || '',
        });
      } catch (error) {
        console.error('Error fetching category:', error);
        // Optionally handle error
      }
    };
  
    // Check if categoryId exists before dispatching the action
    if (categoryId) {
      fetchData();
    }
  }, [dispatch, categoryId]);
  





  // useEffect(() => {
  //   if (singleCats) {
  //     setSingleCats({
  //       categoryName: singleCat.categoryName || '',
  //       description: singleCat.description || '',
  //       status: singleCat.status || '',
  //     });
  //   }
  // }, [singleCat, categoryId]);


  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSingleCats(prevSingleCats => ({
      ...prevSingleCats,
      [name]: value,
    }));
  };
  

  // handleSubmit function
const handleSubmit = async (e) => {
  e.preventDefault();

  console.log('CAA', categoryId);
  console.log('DDD', formData);
  try {
    // Dispatch the updateCategory action with categoryId and formData
    await dispatch(updateCategory({ categoryId, formData: singleCats }));
    setSuccessMessage('Category updated successfully!');
    // Optionally, you can reset the form after success
    setFormData({
      parentCategory: '',
      categoryName: '',
      description: '',
      status: '',
    });
  } catch (error) {
    console.error('Error updating category:', error);
  }
};
  return (
    <div className="container">
      <h2>Add Category</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="parentCategory" className="form-label">
            Parent Category
          </label>
          <select
  className="form-select"
  id="parentCategory"
  name="parentCategory"
  value={singleCats.parentCategory || ''}
  onChange={handleChange}
>
  <option value="">Select Parent Category</option>
  {categories.map((category) => (
    <option key={category._id} value={category._id}>
      {category.categoryName}
    </option>
  ))}
</select>


        </div>

        {/* <div className="mb-3">
          <label htmlFor="categoryName" className="form-label">
            Category Name
          </label>
          <input
            type="text"
            className="form-control"
            id="categoryName"
            name="categoryName"
            value={singleCats.categoryName}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={singleCats.description}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <select
            className="form-select"
            id="status"
            name="status"
            value={singleCats.status}
            onChange={handleChange}
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
       */}

       <input
  type="text"
  className="form-control"
  id="categoryName"
  name="categoryName"
  value={singleCats.categoryName}
  onChange={handleChange}
/>

<textarea
  className="form-control"
  id="description"
  name="description"
  value={singleCats.description}
  onChange={handleChange}
></textarea>
<select
  className="form-select"
  id="status"
  name="status"
  value={singleCats.status}
  onChange={handleChange}
>
  <option value="">Select Status</option>
  <option value="active">Active</option>
  <option value="inactive">Inactive</option>
</select>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <div className="text-danger">{error}</div>}
      </form>
    </div>
  );
}

export default EditCategoryForm;
