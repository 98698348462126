// // categoryApi.js
// import axios from 'axios';

// const BASE_URL = 'http://localhost:5000/api/categories';

// export const addCategory = async (categoryData) => {
//     try {
//       console.log('Sending request to add category with data:', categoryData); // Log the data being sent in the request
//       const response = await axios.post(`${BASE_URL}/add`, categoryData);
//       console.log('Received response data:', response.data); // Log the response data
//       return response.data;
//     } catch (error) {
//       console.error('Error adding category:', error); // Log any errors that occur
//       throw new Error('Failed to add category');
//     }
//   };

// export const updateCategory = async (categoryId, categoryData) => {
//   try {
//     const response = await axios.put(`${BASE_URL}/update/${categoryId}`, categoryData);
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to update category');
//   }
// };

// export const deleteCategory = async (categoryId) => {
//   try {
//     const response = await axios.delete(`${BASE_URL}/delete/${categoryId}`);
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to delete category');
//   }
// };

// export const fetchAllCategories = async () => {
//   try {
//     const response = await axios.get(`${BASE_URL}/all`);
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to fetch categories');
//   }
// };

// export const fetchCategoryById = async (categoryId) => {
//   try {
//     const response = await axios.get(`${BASE_URL}/${categoryId}`);
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to fetch category');
//   }
// };


// categoryAPI.js

// // Example API function for adding a category
// export const addCategoryAPI = async (formData) => {
//     try {
//       // Make a POST request to your backend API endpoint to add the category
//       const response = await fetch('http://localhost:5000/api/categories/add', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           // Add any additional headers if needed, such as authorization token
//         },
//         body: JSON.stringify(formData),
//       });
  
//       // Check if the request was successful
//       if (!response.ok) {
//         // Handle the error by throwing an error or returning an error message
//         throw new Error('Failed to add category');
//       }
  
//       // If the request was successful, parse the response data and return it
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       // Handle any errors here, such as logging the error or throwing it again
//       console.error('Error adding category:', error.message);
//       throw error;
//     }
//   };
  

// with AXIOS


import axios from 'axios';

// export const addCategoryAPI = async (formData) => {
//   try {
//     const response = await axios.post('http://localhost:5000/api/categories/add', formData, {
//       headers: {
//         'Content-Type': 'application/json',
//         // Add any additional headers if needed, such as authorization token
//       },
//     });

//     // Check if the request was successful
//     if (response.status !== 200) {
//       // Handle the error by throwing an error or returning an error message
//       throw new Error('Failed to add category');
//     }

//     // If the request was successful, return the response data
//     return response.data;
//   } catch (error) {
//     // Handle any errors here, such as logging the error or throwing it again
//     console.error('Error adding category:', error.message);
//     throw error;
//   }
// };


const BASE_URL = 'https://react-ecom.acesoftech.co.in/api/categories';

export const addCategoryAPI = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/add`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to add category');
    }

    return response.data;
  } catch (error) {
    console.error('Error adding category:', error.message);
    throw error;
  }
};

export const updateCategoryAPI = async (categoryId, formData) => {
  // console.log('CAA NEXT', categoryId);
  // console.log('DDD NEXT', formData);
  try {
    const response = await axios.put(`${BASE_URL}/update/${categoryId}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    if (response.status !== 200) {
      throw new Error('Failed to update category');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating category:', error.message);
    throw error;
  }
};



export const deleteCategoryAPI = async (categoryId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/delete/${categoryId}`);
   // console.log('Status',response.status);
    if (response.status !== 200) {
      throw new Error('Failed to delete category');
    }

    return response.data;
  } catch (error) {
    console.error('Error deleting category:', error.message);
    throw error;
  }
};

export const fetchAllCategoriesAPI = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/all`);
    console.log('CATSSSSS', response.data); // Moved console.log before return statement
    return response.data;
  } catch (error) {
    console.error('Error fetching all categories:', error.message);
    throw error;
  }
};


export const fetchCategoryByIdAPI = async (categoryId) => {
  
  try {

    //console.log(`${BASE_URL}/${categoryId}`);
    const response = await axios.get(`${BASE_URL}/${categoryId}`);
   // console.log('AAPS',response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching category by ID:', error.message);
    throw error;
  }
};