import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Importing useNavigate hook
import { fetchSingleBlogArticle, updateBlogArticle } from '../../slices/blogSlice';

const EditBlogArticle = ({ match }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Using useNavigate hook
  const { articleId } = match.params;

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [content, setContent] = useState('');
  const [featured, setFeatured] = useState(false);

  const article = useSelector(state => state.blogArticles.article);
  const status = useSelector(state => state.blogArticles.status);
  const error = useSelector(state => state.blogArticles.error);

  useEffect(() => {
    dispatch(fetchSingleBlogArticle(articleId));
  }, [articleId, dispatch]);

  useEffect(() => {
    if (status === 'succeeded' && article) {
      setTitle(article.title);
      setImage(article.image);
      setContent(article.content);
      setFeatured(article.featured);
    }
  }, [status, article]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedArticle = {
      id: articleId,
      title,
      image,
      content,
      featured
    };

    dispatch(updateBlogArticle(updatedArticle));
    navigate('/view-blog-articles'); // Redirect to view blog articles page using navigate
  };

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Edit Blog Article</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input type="text" className="form-control" id="image" value={image} onChange={(e) => setImage(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">Content</label>
          <textarea className="form-control" id="content" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="featured" checked={featured} onChange={(e) => setFeatured(e.target.checked)} />
          <label className="form-check-label" htmlFor="featured">Featured</label>
        </div>
        <button type="submit" className="btn btn-primary">Update Article</button>
      </form>
    </div>
  );
};

export default EditBlogArticle;
