import React, { useEffect, useState } from 'react'; // Import useState
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser, selectLoading, selectError, logoutUser } from  '../../slices/userSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // useNavigate hook for navigation
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [storedUser, setStoredUser] = useState(null); // Define storedUser using useState

 

  useEffect(() => {
    // console.log('USERR', user);

    // Check if the user is not logged in
    const userFromSession = JSON.parse(sessionStorage.getItem('user'));
    const tokenFromSession = sessionStorage.getItem('token');
 

    // console.log('UUUUU',userFromSession)
  
    if (user || userFromSession || tokenFromSession) {
      if(user.user!=''){
          setStoredUser(user.user); // Set storedUser state
      }
      else{
        setStoredUser(userFromSession); // Set storedUser state
      }
       
    } else {
      //navigate('/login'); // Navigate to login page
    }
  }, [user, loading, error, navigate]);


 
  return (
    <div>
      {storedUser && ( // Check if storedUser is available before rendering
        <div>
           <h2>Welcome, {user.user.firstName}</h2> 
          
         <p>Email: {user.user.email}</p>
       
          {/* <button onClick={handleLogout}>Logout</button> */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
