import { configureStore } from '@reduxjs/toolkit';
import categorySlice from '../slices/categorySlice';
import productSlice from '../slices/productSlice'; // Import the product slice
import pathReducer from '../slices/globalpaths';
import userSlice  from '../slices/userSlice';
import blogArticleSlice from '../slices/blogSlice';
import blogCategorySlice from '../slices/blogCategorySlice';
const store = configureStore({
  reducer: {
    categories: categorySlice,
    products: productSlice,
    path: pathReducer,
    user: userSlice,
    blogArticles: blogArticleSlice,
    blogCategories:blogCategorySlice
    // Add the product slice reducer
    // Add other reducers if any
  },
});

export default store;
