import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../../slices/productSlice';
import { fetchAllCategories } from '../../slices/categorySlice';

function AddProductForm() {
  const dispatch = useDispatch();
  const { loading, error, categories } = useSelector((state) => state.categories);

  const [formData, setFormData] = useState({
    category: '',
    productName: '',
    description: '',
    price: '',
    product_image: [], // State to store multiple images
  });

  const [successMessage, setSuccessMessage] = useState('');

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  const handleChange = (e) => {
    if (e.target.name === 'product_image') {
      // Handle multiple image selection
      const files = Array.from(e.target.files);
      setFormData({ ...formData, product_image: files });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const productData = new FormData();

      // Add non-image data to FormData
      productData.append('category', formData.category);
      productData.append('productName', formData.productName);
      productData.append('description', formData.description);
      productData.append('price', formData.price);

      // Add images under the 'product_image' key
      formData.product_image.forEach((file, index) => {
        productData.append('product_image', file);
      });

     console.log('AAAA',productData);

      await dispatch(addProduct(productData));
      setSuccessMessage('Product added successfully!');
      // Optionally, you can reset the form after success
      setFormData({
        category: '',
        productName: '',
        description: '',
        price: '',
        product_image: [],
      });
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <div className="container">
      <h2>Add Product</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category
          </label>
          <select
            className="form-select"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>{category.categoryName}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="productName" className="form-label">
            Product Name
          </label>
          <input
            type="text"
            className="form-control"
            id="productName"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="price" className="form-label">
            Price
          </label>
          <input
            type="number"
            className="form-control"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="images" className="form-label">
            Images
          </label>
          <input
            type="file"
            className="form-control"
            id="product_image"
            name="product_image"
            onChange={handleChange}
            multiple // Allow multiple file selection
          />
        </div>
      
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {error && <div className="text-danger">{error}</div>}
      </form>
    </div>
  );
}

export default AddProductForm;
