import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory } from '../../slices/blogCategorySlice';

const AddBlogCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.blogCategories.categories);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState('');
  const [active, setActive] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCategory({ name, description, parentId, active }));
    setName('');
    setDescription('');
    setParentId('');
    setActive(true);
  };

  return (
    <div>
      <h2>Add New Category</h2>
      <form onSubmit={handleSubmit}>

      <div className="mb-3">
          <label htmlFor="parentId" className="form-label">Parent Category</label>
          <select className="form-select" id="parentId" value={parentId} onChange={(e) => setParentId(e.target.value)}>
            <option value="0">Select Parent Category</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
       
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="active" checked={active} onChange={(e) => setActive(e.target.checked)} />
          <label className="form-check-label" htmlFor="active">Active</label>
        </div>
        <button type="submit" className="btn btn-primary">Add Category</button>
      </form>
    </div>
  );
};

export default AddBlogCategory;
